define([
  'backbone',
  'backbone.marionette',
],
(Backbone) => {
  const Communicator = Backbone.Marionette.Controller.extend({
    initialize() {
      // create a pub sub
      this.mediator = new Backbone.Wreqr.EventAggregator();

      // create a req/res
      this.reqres = new Backbone.Wreqr.RequestResponse();

      // create commands
      this.command = new Backbone.Wreqr.Commands();

      // setup radio
      this.radio = Backbone.Wreqr.Radio;
    },
  });

  return new Communicator();
});
